.Pricing-header {
  font-weight: bold;
}

.Pricing-container {
  border: none; 
  margin-top: 30px;
  padding: 30px;
  margin-bottom: 30px;
}

.Pricing-column {
  border: 1px solid #7EBEE1;
  /*text-align: center;*/
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.Pricing-price-row {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
  font-weight: 500;
}

.Pricing-feature-row {
  font-size: 14px;
  text-align: center;
}

.Pricing-name-row {
  font-size: 25px;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 50px !important;
}

.Pricing-name-row.hobby {
  color: #F6E550;
}
.Pricing-name-row.explore {
  color: #E5644A;
}
.Pricing-name-row.serious {
  color: #7EBEE1;
}

.Pricing-check {
  color: #7EBEE1;
}

@media (min-width: 768px) {
  .Pricing-name-row {
    margin-top: 0px !important;
  }

  .Pricing-column {
    /*border-right: 1px solid #7EBEE1;*/
    border-right: none;
    text-align: left;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 30px;
  }

  .Pricing-column:last-child {
    border-right: 1px solid #7EBEE1;
  }

  .Pricing-feature-row {
    text-align: left;
  }
}
