.AboveTheFold-container {
  /*min-height: 750px;*/
  color: black;
  background-color: white;/*#ecd3e2;/*#324051;*/ /* #ef8de4;*/
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.AboveTheFold-title {
  text-align: center;
  font-size: 35px;
  font-weight: bolder;
  color: #7EBEE1;
}

.AboveTheFold-subtitle {
  white-space: pre-wrap;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 20px;
}

.AboveTheFold-image {
  object-fit: contain;
  width: 100%;
}

.AboveTheFold-button {
  width: 100%;
}

@media (min-width: 768px) {
  .AboveTheFold-image {
    height: 500px;
  }

  .AboveTheFold-container {
    padding-left: 40px !important;
    height: 600px;
  }

  .AboveTheFold-title {
    text-align: left;
    font-size: 40px;
  }

  .AboveTheFold-subtitle {
    text-align: left;
  }

  .AboveTheFold-button {
    width: 150px;
  }
}

