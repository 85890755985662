.SummaryBlurb-container {
  white-space: pre-wrap;
  font-weight: bold;
  font-size: 20px;
}

@media (min-width: 768px) {
  .SummaryBlurb-container {
    font-size: 25px;
  }
}
