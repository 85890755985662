.Navigation-logo {
  height: 30px;
  object-fit: contain;
  margin-right: 10px; 
  margin-left: 10px;
}

.Navigation-brand-name {
  font-weight: 500;
}
