.SocialProof-container {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 100px;
  font-size: 20px;
  white-space: pre-wrap;
}

.SocialProof-image-square {
  object-fit: contain;
  height: 40px;
}
