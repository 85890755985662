.BenefitDetails-container {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 400px;
}

.BenefitDetails-placeholder-image {
  object-fit: contain;
  width: 100%;
}

.BenefitDetails-title {
  padding-top: 20px;
  color: #7EBEE1;
  font-weight: bold;
}

.BenefitDetails-description {
  white-space: pre-wrap;
  font-size: 20px;
}
